<div class="info">
  <div class="info__name">
    <span *ngIf="index">№{{ index }}</span>
    {{ approverGroup.name }}
  </div>
  <div class="info__status">
    <tui-tag
      size="l"
      class="status-tag"
      [status]="approverGroup.statusColor"
      [value]="approverGroup.statusName"
    ></tui-tag>
  </div>
  <div *ngIf="approverGroup.description" class="info__desc">
    {{ approverGroup.description }}
  </div>
</div>
<div class="approvers">
  <div *ngFor="let approver of approverGroup.approvers" class="approver">
    <div class="approver__header">
      <tui-badge
        size="s"
        class="status-tag"
        class="approver__status"
        [status]="approver.statusColor"
      >
        <span class="approver__dot"></span>
      </tui-badge>
      <ng-container
        *ngTemplateOutlet="
          employeePageUrl && approver.employeeForSiteId
            ? approverLink
            : approverNoLink;
          context: {$implicit: approver}
        "
      ></ng-container>
    </div>
    <div *ngIf="approver.comment" class="approver__comment">
      {{ approver.comment }}
    </div>
  </div>
</div>

<ng-template #approverLink let-approver>
  <a
    tuiLink
    target="_blank"
    [href]="employeePageUrl + '/' + approver.employeeForSiteId"
  >
    {{ approver.fullName }}
  </a>
</ng-template>

<ng-template #approverNoLink let-approver>
  <span>{{ approver.fullName }}</span>
</ng-template>
