<ng-container *ngIf="request$ | async as request">
  <div class="header">
    <div class="header__meta">
      <div class="request-number">{{ request.requestId | requestNumber }}</div>
      <div class="request-dates">
        <div class="request-dates__entry">
          <span>{{ request.created | date: 'dd.MM.YYYY' }}</span>
        </div>
      </div>
    </div>
    <div class="header__status">
      <tui-tag
        size="l"
        class="status-tag"
        [value]="request.statusName"
        [status]="request.statusColor"
      ></tui-tag>
    </div>
  </div>

  <div class="title">
    <div class="title__text">
      {{ request.title }}
    </div>
    <div class="title__actions action">
      <!-- Включить если потребуется отображение даты окончания согласования -->
      <div *ngIf="dueDate$ | async as dueDate" class="dismissial-date">
        <span class="dismissial-date__value">
          Необходимо согласовать до {{ dueDate | date: 'dd.MM.YYYY' }}
        </span>
      </div>

      <div class="action__list">
        <ng-container *ngIf="canCancel$ | async">
          <button
            *ngIf="hasCancelRights$ | async"
            tuiButton
            appearance="flat"
            size="s"
            class="action__button"
            (click)="cancelRequest()"
          >
            Отозвать
          </button>
        </ng-container>
      </div>

      <!-- TODO: Кнопка "Поделиться" для экспорта в печать или ссылку -->
      <!-- <button
        size="s"
        tuiIconButton
        icon="tuiIconUpload"
      ></button> -->
    </div>
  </div>

  <!-- <div class="request-properties">
    <a
      tuiLink
      href="#"
    >
      {{ request.author }}
    </a>
  </div> -->

  <div class="view-part">
    <div class="view-part__header">
      <div class="view-part__title">Сотрудник</div>
    </div>
    <div class="view-part__body">
      <app-employee-card [employee]="request.employee"></app-employee-card>
    </div>
  </div>

  <div class="view-part">
    <div class="view-part__header">
      <div class="view-part__title">Сведения</div>
    </div>

    <div class="view-part__body" [ngSwitch]="request.typeCode">
      <div *ngSwitchCase="'infoaccessv2'" class="resource-fields">
        <app-resource-details-second
          [request]="request"
        ></app-resource-details-second>
      </div>

      <sd-request-fields
        *ngSwitchDefault
        [fields]="visibleFields$ | async"
      ></sd-request-fields>
    </div>
  </div>

  <ng-container *tuiLet="attachments$ | async as attachments">
    <div *ngIf="attachments && attachments.length > 0" class="view-part">
      <div class="view-part__header">
        <div class="view-part__title">Файлы</div>
      </div>
      <div class="view-part__body">
        <!-- TODO: Заменить на TuiFile после обновления тайги -->
        <div *ngFor="let file of attachments">
          <a tuiLink target="_blank" [href]="file.webViewLink">
            {{ file.fileName }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container [ngSwitch]="request.typeCode">
    <ng-container *ngSwitchCase="'infoaccessv2'"></ng-container>
    <ng-container *ngSwitchDefault>
      <div class="view-part">
        <div class="view-part__header">
          <div class="view-part__title">Согласующие</div>
        </div>

        <div *ngIf="request.statusCode === 'New'" class="view-part__alert">
          Процесс согласования скоро начнётся. Если этого не&nbsp;происходит
          продолжительное время&nbsp;&mdash; отправьте нам запрос
          на&nbsp;0911@pik.ru
        </div>

        <div *ngIf="request.statusCode !== 'New'" class="view-part__body">
          <sd-request-approver-group-list
            *ngIf="approveSteps$ | async as approveSteps"
            [steps]="approveSteps"
          ></sd-request-approver-group-list>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
