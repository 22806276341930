import {
  FullAccessRequestDto,
  FullRequestDto,
  RequestDto,
} from '@app/libs/servicedesk-api';
import {humanizeDateRange} from '@app/utils';

import {SDFullAccessRequest, SDFullRequest} from '../components';
import {SDRequest} from '../interfaces';
import {STATUS_COLOR} from './status-color';
import {Field} from '@app/core/interfaces/field';

const RequestTypeToTitle: {[requestType: string]: string} = {
  terminationchecklistv2: 'Обходной лист',
  vacationv2: 'Отпуск',
  errandv2: 'Командировка',
  twondflv2: '2-НДФЛ',
  daysoff: 'Отгул',
  personaldatav2: 'Изменение персональных данных',
  employmenthistorycopyv2: 'Копия трудовой',
  employmentdocumentv2: 'Справка о трудоустройстве',
  infoaccessv2: 'Заявка на доступ',
  recruitmentv2: 'Подбор перcонала',
  massrecruitment: 'Массовый подбор перcонала',
  firing: 'Увольнение',
  weekend: 'Выход на работу в праздничный или выходной день',
};

const RequestCodeById = {
  1: 'vacation',
  2: 'infoaccess',
  3: 'terminationchecklist',
  4: 'employmentdocument',
  5: 'singlenonpaymentchildbirthdocument',
  6: 'monthnonpaymentchildbirthdocument',
  7: 'employmenthistorycopy',
  8: '2ndfl',
  9: 'recruitment',
  10: 'employeereception',
  11: 'errand',
  12: 'infoaccessv2',
  13: 'personaldata',
  14: 'recruitmentv2',
  15: 'firing',
  16: 'errandv2',
  17: 'weekend',
  18: 'singlenonpaymentchildbirthdocumentv2',
  19: 'monthnonpaymentchildbirthdocumentv2',
  20: 'massrecruitment',
  21: 'employmenthistorycopyv2',
  22: 'twondflv2',
  23: 'employmentdocumentv2',
  24: 'personaldatav2',
  25: 'terminationchecklistv2',
  26: 'releaseemployees',
  27: 'daysoff',
  28: 'vacationv2',
  29: 'createstafflist',
  30: 'modifystafflist',
  31: 'deletestafflist',
  62: 'plannedvacation',
  60: 'promotion',
};

export function convertRequestDto(request: RequestDto): SDRequest {
  return {
    id: request.id,
    requestId: request.id,
    created: request.created,
    createdMonth: new Date(request.created).toLocaleDateString('ru-RU', {
      month: 'long',
      year: 'numeric',
    }),

    typeId: request.typeId,
    typeCode: request.typeCode,
    typeName: request.typeName,
    typeSource: request.typeSource,

    statusId: request.statusId,
    statusName: request.statusName,

    title: RequestTypeToTitle[request.typeCode] || request.typeName,
    statusColorCode: STATUS_COLOR[request.statusCode] || 'default',
    statusColor: request.statusColor,

    employeeId: request.employeeId,
    employeeIndividualGuid1C: request.employeeIndividualGuid1C,
    employeeFullName: request.employeeFullName,
    authorIndividualGuid1C: request.authorIndividualGuid1C,
    authorFullName: request.authorFullName,
  };
}

/**
 * Функция преобразования результата запроса GET /request/full/{id}
 *
 * Для заявки на доступ необходим отдельный обработчик,
 * так как модель ответа сильно отличается
 */
export function convertFullRequestDto(
  request: FullRequestDto | FullAccessRequestDto,
): SDFullRequest | SDFullAccessRequest {
  if (isAccessRequest(request)) {
    return {
      id: request.id,
      requestId: request.requestId,
      created: request.created,
      modified: request.modified,

      typeId: request.typeId,
      typeCode: 'infoaccessv2',
      typeName: request.typeName,

      statusId: request.status.id,
      statusCode: request.status.code,
      statusName: request.status.name,

      title: getAccessTitleExtra(request),
      statusColorCode: STATUS_COLOR[request.status.code] || 'default',
      statusColor: request.status.color,

      fields: [],

      authorId: request.authorId,
      author: null,

      manager: request.manager,

      employeeId: request.employee.employeeGuid1C,
      employee: request.employee,

      typeByUserId: request.typeByUserId,
      typeByUserCode: request.typeByUserCode,
      typeByUserName: request.typeByUserName,

      contractorDateOfBirth: request.contractorDateOfBirth,
      contractorEmail: request.contractorEmail,
      contractorCompanyName: request.contractorCompanyName,
      contractorNda: request.contractorNda,
      contractorStartDate: request.contractorStartDate,
      userResources: request.userResources,

      commentForAuditors: request.commentForAuditors,
      managerComment: request.managerComment,
    };
  } else {
    const typeCode = RequestCodeById[request.typeId] || null;
    let title = request.typeName;

    if (typeCode !== null) {
      title = (RequestTypeToTitle[typeCode] || title) + getTitleExtra(typeCode, request);
    }

    return {
      id: request.id,
      requestId: request.requestId,
      created: request.created,
      modified: request.modified,

      typeId: request.typeId,
      typeCode,
      typeName: request.typeName,

      statusId: request.statusId,
      statusCode: request.statusCode,
      statusName: request.statusName,

      title,
      statusColorCode: STATUS_COLOR[request.statusCode] || 'default',
      statusColor: request.statusColor,

      fields: request.fields,

      authorId: request.authorId,
      author: null,

      employeeId: request.employeeId,
      employee: request.employee,
    };
  }
}

export function filterFieldsByType(request: SDFullRequest): Field[] {
  const fields = request.fields || [];

  switch (request.typeCode) {
    case 'promotion':
      return fields.filter((field: Field) => !!field.value);

    case 'recruitmentv2': {
      return fields.map((field: Field) => {
        if (
          field.code === 'requirements_additional' ||
          field.code === 'requirements' ||
          field.code === 'body'
        ) {
          field.value = extractContentFromHtml(field.value);
        }

        return field;
      });
    }

    case 'twondflv2': {
      return fields.map((field: Field) => {
        if (field.code === 'employeehistory') {
          field.value = extractContentFromHtml(field.value);
        }

        return field;
      });
    }

    case 'terminationchecklistv2': {
      const transferType = fields.find(
        (field: Field) => field.code === 'isTransferToAnotherCompanyView',
      )?.value;

      if (transferType === 'Перевод') {
        return fields.filter((field: Field) => field.code !== 'approversView');
      }

      return fields;
    }

    default:
      return fields;
  }
}

function extractContentFromHtml(text: string) {
  try {
    const html = new DOMParser().parseFromString(text, 'text/html');

    if (html.body.children.length > 0) {
      let content = '';
      const span = document.createElement('span');

      span.innerHTML = text;
      const lists = span.getElementsByTagName('li');

      if (lists.length) {
        span.innerHTML = Array.from(lists)
          .map(li => li.innerText)
          .join('\n');
      }

      content = span.textContent || span.innerText;

      span.remove();

      return content;
    }

    return text;
  } catch (error) {
    return text;
  }
}

function getTitleExtra(type: string, request: FullRequestDto): string {
  switch (type) {
    case 'daysoff':
    case 'vacationv2': {
      const {startDateEKS, endDateEKS}: {[key: string]: string} = request.fields.reduce(
        (acc, cur) => {
          if (cur.code === 'startDateEKS' || cur.code === 'endDateEKS') {
            return {...acc, [cur.code]: cur.value};
          }

          return acc;
        },
        {},
      );

      return ` •\xa0${startDateEKS} \u2014 ${endDateEKS}`;
    }

    case 'errandv2': {
      const {eksErrandDates}: {[key: string]: string} = request.fields.reduce(
        (acc, cur) => {
          if (cur.code === 'eksErrandDates') {
            return {...acc, [cur.code]: cur.value};
          }

          return acc;
        },
        {},
      );

      return ` •\xa0${eksErrandDates}`;
    }

    case 'terminationchecklistv2': {
      const {dateView}: {[key: string]: string} = request.fields.reduce((acc, cur) => {
        if (cur.code === 'dateView') {
          return {...acc, [cur.code]: cur.value};
        }

        return acc;
      }, {});

      return ` •\xa0увольнение  \u2014 ${dateView}`;
    }

    default:
      return '';
  }
}

function isAccessRequest(
  request: FullRequestDto | FullAccessRequestDto,
): request is FullAccessRequestDto {
  return (request as FullAccessRequestDto).typeId === 12;
}

function getAccessTitleExtra(reuqest: FullAccessRequestDto): string {
  const typeCode = reuqest.typeByUserCode;
  let title = 'Заявка на доступ ';

  switch (typeCode) {
    case 'Auditor':
      title += ` для аудитора`;
      break;
    case 'Contractor':
      title += ` для подрядчика`;
      break;

    case 'Employee':
      title += ` для сотрудник${
        reuqest.userResources && reuqest.userResources.length > 1 ? 'ов' : 'а'
      }`;
      break;

    case 'Individual':
      title += ` для физического лица`;
      break;

    default:
      break;
  }

  return `${title} •\xa0${
    reuqest.startDate
      ? humanizeDateRange(reuqest.startDate, reuqest.endDate)
      : 'бессрочный'
  }`;
}
