import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';

import {Subject} from 'rxjs';
import {SDRequest} from '../../interfaces';
import {OPENED_REQUEST} from '../request-list/tokens';
import {tuiDefaultProp} from '@pik-taiga-ui/cdk';
import {TuiStatus} from '@pik-taiga-ui/kit';

@Component({
  selector:
    'sd-request-card, a[sdRequestCard]:not([routerLink]), a[sdRequestCard][routerLink][routerLinkActive]',
  templateUrl: './request-card.template.html',
  styleUrls: ['./request-card.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestCardComponent {
  @Input()
  @tuiDefaultProp()
  datePipeFormat = 'dd.MM.YYYY';
  // datePipeFormat = 'dd\xa0MMMM\xa0YYYY';

  @Input()
  request: SDRequest;

  @Input()
  @tuiDefaultProp()
  showEmployee = false;

  @Input()
  @tuiDefaultProp()
  showAuthor = false;

  /** В случаях навешивания routerLink на ссылки - директива не подвешивается */
  @Input('routerLink') routerLinkAttr?: string;

  @Input()
  @HostBinding('class._colored')
  colored = false;

  @HostBinding('[attr.data-status]')
  get requestStatusColor() {
    return this.request.statusColor;
  }

  @HostBinding('class._clickable')
  get isClickable(): boolean {
    return !!this.routerLinkDirective || !!this.routerLinkAttr;
  }

  @HostListener('click') onClick() {
    this.isActive && this.openedRequest$.next(this.request);
  }

  @HostBinding('class._active')
  get isActive(): boolean {
    return !!this.routerLinkActive && this.routerLinkActive.isActive;
  }

  @HostBinding('class._hover')
  hovered = false;

  constructor(
    @Inject(OPENED_REQUEST)
    private readonly openedRequest$: Subject<SDRequest | null>,
    @Optional()
    @Inject(RouterLinkActive)
    private readonly routerLinkActive: RouterLinkActive | null,
    @Optional()
    @Inject(RouterLink)
    private readonly routerLinkDirective: RouterLink | null,
  ) {}
}
